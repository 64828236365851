import React, { useEffect, useRef, useContext, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AppContext } from "../../App";
import "./Toolbar.css";
import "./Themes.css";
import BackIcon from "./back-arrow.svg";
import MuteIcon from "./mute.svg";
import axios from "axios";

const Toolbar = (props) => {
  const activeSectionRef = useRef("popular");

  const { resId } = useParams();

  const location = useLocation();
  const navigation = useNavigate();
  const context = useContext(AppContext);

  const lastURL = location.state ? location.state.prevURL : -1;

  const changeSectionToolbar = async () => {
    const section = document.querySelector(
      `.section-title#${activeSectionRef.current}`
    );
    const sectionBar = document.querySelector(".section-bar");
    const sectionTitle = sectionBar.querySelector(
      `.section#${activeSectionRef.current}`
    );
    const distance = sectionTitle.getBoundingClientRect().left;
    const toolbarHeight =
      document.querySelector(".Toolbar").getBoundingClientRect().height + 10;

    if (distance >= 70 || distance <= -70) {
      await sectionBar.scrollTo({
        left: sectionTitle.offsetLeft - 20,
        behavior: "smooth",
      });
    }
  };

  const goTo = async (sectionId) => {
    // activeSectionRef.current = sectionId;

    const section = document.querySelector(`.section-title#${sectionId}`);
    const sectionBar = document.querySelector(".section-bar");
    const sectionTitle = sectionBar.querySelector(`.section#${sectionId}`);
    const distance = sectionTitle.getBoundingClientRect().left;
    const toolbarHeight =
      document.querySelector(".Toolbar").getBoundingClientRect().height + 10;

    if (distance >= 70 || distance <= -70) {
      sectionBar.scrollTo({
        left: sectionTitle.offsetLeft - 20,
        behavior: "smooth",
      });
    }

    window.scrollTo({
      top: section.offsetTop - toolbarHeight,
      behavior: "smooth",
    });
  };

  const goBack = () => {
    navigation(lastURL);
  };

  const handleMute = async (e) => {
    e.stopPropagation();

    // Get video in the middle of the screen
    const videos = await document.querySelectorAll("video");

    if (videos.length) {
      const bottom = window.innerHeight;
      let video = await null;

      await videos.forEach(async (each) => {
        // Check if the video is in the middle of the screen
        const rect = each.getBoundingClientRect();
        if (
          rect.top + rect.height / 2 >= 0 &&
          rect.top + rect.height <= bottom
        ) {
          // Switch the video to mute or unmute
          let video = await each;
          let videoId = await video.parentElement.parentElement.id;

          let specificVideo = await document.querySelector(`#${videoId} video`);

          if (specificVideo.autoplay) {
            await specificVideo.pause();
            specificVideo.autoplay = await false;
            specificVideo.muted = await false;
            await specificVideo.play();
          } else {
            await specificVideo.pause();
            specificVideo.muted = await true;
            specificVideo.autoplay = await true;
            await specificVideo.play();
          }
        }
      });
    }
  };

  const toTop = async () => {
    const sectionBar = await document.querySelector(".section-bar");

    await sectionBar.scrollTo({
      left: 0,
      behavior: "smooth",
    });
    await window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll(".section-title");
    const toolbarHeight =
      document.querySelector(".Toolbar").getBoundingClientRect().height + 10;
    const middle = window.innerHeight / 2;

    sections.forEach(async (section) => {
      const rect = section.getBoundingClientRect();
      if (
        rect.top + rect.height / 2 >= toolbarHeight &&
        rect.top + rect.height / 2 < middle
      ) {
        if (activeSectionRef.current != section.id) {
          const sectionBar = document.querySelector(".section-bar");
          const sectionTitle = sectionBar.querySelector(
            `.section#${section.id}`
          );
          const distance = sectionTitle.getBoundingClientRect().left;

          if (distance >= 70 || distance <= -70) {
            sectionBar.scrollTo({
              left: sectionTitle.offsetLeft - 20,
              behavior: "smooth",
            });
          }
        }
        activeSectionRef.current = await section.id;
      }
    });

    // Set .active class on the section id that is equal to activeSectionRef.current
    document.querySelectorAll(".Toolbar .section").forEach((section) => {
      if (section.id === activeSectionRef.current) {
        section.classList.add("active-section-highlight");
      } else {
        section.classList.remove("active-section-highlight");
      }
    });
  };

  const [logo, setLogo] = useState("");
  const [sections, setSections] = useState([]);
  const [name, setName] = useState("");
  const [theme, setTheme] = useState("");
  const [color, setColor] = useState("");

  const getRestaurantToolbarInfo = async () => {
    axios
      .get(
        `${process.env.REACT_APP_DOMAIN}/api/restaurant/get-toolbar/${context.restaurantId}`
      )
      .then(async (res) => {
        await setLogo(res.data.logo);
        await setSections(res.data.sections);
        await setName(res.data.name);
        await setTheme(res.data.theme);
        await setColor(res.data.color);
      });
  };

  useEffect(() => {
    // Get the restaurant data for the toolbar
    getRestaurantToolbarInfo();

    // Set .active class on the section id that is equal to activeSectionRef.current
    document.querySelectorAll(".Toolbar .section").forEach((section) => {
      if (section.id === activeSectionRef.current) {
        section.classList.add("active-section-highlight");
      } else {
        section.classList.remove("active-section-highlight");
      }
    });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [context.name]);

  return context.activeToolbar ? (
    <div className="ActiveToolbar">
      <div className="section">
        <div onClick={goBack} className="action-button">
          <img className="icon" src={BackIcon} alt="Back Icon"></img>
        </div>
      </div>
      <div className={`${theme} logo-section`} onClick={goBack}>
        {(!logo || logo === "/") && (
          <div style={{ color: `white` }}>{name}</div>
        )}
        {logo && logo !== "/" && (
          <img className="logo" src={logo} alt="Restaurant Logo"></img>
        )}
      </div>
      <div className="section">
        <div className="action-button">
          <img
            className="icon"
            src={MuteIcon}
            onClick={handleMute}
            alt="Mute Icon"
          ></img>
        </div>
      </div>
    </div>
  ) : (
    <div className="Toolbar">
      <div className="toolbar-top">
        <div className="section"></div>
        <div className={`${theme} logo-section`} onClick={toTop}>
          {(!logo || logo === "/") && (
            <div style={{ color: `${color}` }}>{name}</div>
          )}
          {logo && logo !== "/" && (
            <img className="logo" src={logo} alt="Restaurant Logo"></img>
          )}
        </div>
        <div className="section"></div>
      </div>
      <div className="section-bar">
        <div
          className={`section`}
          onClick={() => goTo(`popular`)}
          id={`popular`}
        >
          POPULAR
          <div style={{ flexGrow: 1 }} />
        </div>
        {sections &&
          sections.map((each) => (
            <div
              className={`section`}
              onClick={() => goTo(`section-${each._id}`)}
              id={`section-${each._id}`}
              key={each._id}
            >
              {each.title.toUpperCase()}
              <div style={{ flexGrow: 1 }} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Toolbar;
