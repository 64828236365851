import React, { useEffect, useState, lazy } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Thumbnail from "../components/Thumbnail";
import "./UserProfile.css"; // Create this CSS file for styling
import BottomToolbar from "../components/shared/BottomToolbar";

const UserProfile = (props) => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [topToolbarHidden, setTopToolbarHidden] = useState(true);
  const [likedItems, setLikedItems] = useState([]);

  // Create listener on scroll that sets topToolbarHidden state when scrollTop is greater than the top of .liked-items
  useEffect(() => {
    const handleScroll = () => {
      const likedItems = document.querySelector(".thumbnails");
      if (likedItems) {
        if (window.scrollY > likedItems.offsetTop) {
          setTopToolbarHidden(false);
        } else {
          setTopToolbarHidden(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Set page background color on load
  useEffect(() => {
    document.querySelector("#theme-color").content = "#FFF";
    document.body.style.backgroundColor = "#FFF";
  }, []);

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/users/${userId}`
        );
        await setUser(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    getUserProfile();

    const fetchUserLikedItems = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/users/${userId}/liked-items`
        );
        setLikedItems(response.data.items);
      } catch (error) {
        console.error("Error fetching user liked items:", error);
      }
    };

    fetchUserLikedItems();
  }, []);

  return (
    <>
      <div
        className={`user-scrolling-toolbar${topToolbarHidden ? " hidden" : ""}`}
      >
        <img
          className="profile-pic"
          src={user?.picture}
          alt={`${user?.name}'s profile`}
        />
        <div className="user-info">
          <h1 className="users-name">{user?.name}</h1>
        </div>
      </div>
      <div className="user-profile">
        {user && (
          <div className="user-info">
            <img
              className="profile-pic"
              src={user.picture}
              alt={`${user.name}'s profile`}
            />
            <h1 className="users-name">{user.name}</h1>
            <h3 className="friend-count">
              <span className="count">{user.friendCount}</span> Friend
              {user.friendCount > 1 && "s"}
            </h3>
          </div>
        )}
        <div className="liked-items">
          <h2>Liked Items</h2>
          <div className="thumbnails">
            {likedItems.map((item) => (
              <Thumbnail key={item.item._id} item={item.item} />
            ))}
          </div>
        </div>
      </div>
      <BottomToolbar show active="profile" />
    </>
  );
};

export default UserProfile;
