// BottomToolbar.js
import React from "react";
import "./BottomToolbar.css";
import CompassIcon from "./compass.png";
import SearchIcon from "./search.png";
import CameraIcon from "./camera.png";
import ProfileIcon from "./profile.png";
import { useNavigate } from "react-router-dom";

const BottomToolbar = ({ toggleBottomToolbar, show, active, user }) => {
  const navigate = useNavigate();

  const goToProfile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (user) {
      navigate(`/u/${user._id}`);
    }
  };

  const goToDiscover = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/`);
  };

  return (
    <div className={`bottom-toolbar${show ? " show" : ""}`}>
      <div className={`bottom-toolbar-item`} onClick={goToDiscover}>
        <div className={`bottom-toolbar-icon-container`}>
          <img
            src={CompassIcon}
            className={`bottom-toolbar-icon ${
              active == "discover" ? " active" : ""
            }`}
            alt="Discover"
          />
        </div>
        <div
          className={`bottom-toolbar-item-text ${
            active == "discover" ? " active" : ""
          }`}
        >
          Discover
        </div>
      </div>

      <div className={`bottom-toolbar-item`} onClick={toggleBottomToolbar}>
        <div className={`bottom-toolbar-icon-container`}>
          <img
            src={SearchIcon}
            className={`bottom-toolbar-icon ${
              active == "search" ? " active" : ""
            }`}
            alt="Search"
          />
        </div>{" "}
        <div
          className={`bottom-toolbar-item-text ${
            active == "search" ? " active" : ""
          }`}
        >
          Search
        </div>
      </div>
      <div className={`bottom-toolbar-item`} onClick={toggleBottomToolbar}>
        <div className={`bottom-toolbar-icon-container`}>
          <img
            src={CameraIcon}
            className={`bottom-toolbar-icon ${
              active == "Create" ? " active" : ""
            }`}
            alt="Create"
          />
        </div>{" "}
        <div
          className={`bottom-toolbar-item-text ${
            active == "create" ? " active" : ""
          }`}
        >
          Create
        </div>
      </div>
      <div className={`bottom-toolbar-item`}>
        <div className={`bottom-toolbar-icon-container`}>
          <img
            src={ProfileIcon}
            className={`bottom-toolbar-icon ${
              active == "profile" ? " active" : ""
            }`}
            alt="Profile"
            onClick={goToProfile}
          />
        </div>{" "}
        <div
          className={`bottom-toolbar-item-text ${
            active == "profile" ? " active" : ""
          }`}
        >
          Profile
        </div>
      </div>
    </div>
  );
};

export default BottomToolbar;
